import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import { OutboundLink } from 'gatsby-plugin-google-analytics';
import SEO from '../components/seo';
import { PageTitle } from '../components/styles/global';

export const QUERY = graphql`
  {
    graphAPI {
      allPartners {
        id
        logoUrl
        website
        order
        name
      }
    }
  }
`;

const Partners = ({
  data: {
    graphAPI: { allPartners },
  },
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <SEO title={t('partners')} />
      <PageTitle>{t('partners')}</PageTitle>

      <div className="grid">
        {allPartners.map(({ website, logoUrl, id, name }) => (
          <OutboundLink href={website} target="_blank" key={id}>
            <h2 className="text-center">{name}</h2>
            <img width="350" src={logoUrl} key={id} title={name} />
          </OutboundLink>
        ))}
      </div>
    </>
  );
};

export default Partners;
